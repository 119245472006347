/* Header.css */
.site-header {
    text-align: center;
    padding: 1px;
    background-color: #ffffff;
}

.site-title {
    font-family: 'Arial Black', Gadget, sans-serif; /* Setting the font family to Arial Black */
    font-weight: bold; /* Making the font bold */
    font-size: 5em;
    margin-bottom: 0;
    margin-top: 0;

    @media (max-width: 500px) { /* Adjust max-width to your tablet or smartphone breakpoint */
        font-size: 2em; /* Reduce font size for smaller screens */
    }
}

.site-subtitle {
    font-size: 1.2em;
    margin-bottom: 0;
    margin-top: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;

    @media (max-width: 768px) { /* Adjust max-width to your tablet or smartphone breakpoint */
        font-size: 0.75em; /* Reduce font size for smaller screens */
    }
}

/* Horizontal Menu */
.site-nav {
    margin-top: 20px;
}

.menu {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 40px; /* Spacing between menu items */
}

.menu li {
    display: inline;
}

.menu a {
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 1em;
    color: #333; /* Change color as needed */
    transition: color 0.3s;
}

.menu a:hover {
    color: #007BFF; /* Change color on hover */
}

/* Hamburger Menu */
.menu-toggle {
    display: none;
    font-size: 2em;
    background: none;
    border: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .menu {
        display: none; /* Hide menu by default on smaller screens */
        flex-direction: column; /* Stack menu items vertically */
        gap: 10px;
        margin-top: 10px;
    }

    .menu-open {
        display: flex; /* Show menu when open */
    }

    .menu-toggle {
        display: block; /* Show hamburger icon on smaller screens */
    }
}
