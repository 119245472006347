.support-page {
    text-align: center;
    margin: 20px auto;
    padding: 20px;
    max-width: 800px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
}

h1, h2 {
    color: #333;
    margin-bottom: 15px;
}

p {
    color: #555;
    line-height: 1.6;
    margin-bottom: 15px;
}

ul {
    text-align: left;
    margin: 20px;
    padding: 0;
    list-style-type: disc;
}

li {
    margin-bottom: 15px;
}

strong {
    color: #333;
}

a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.support-email {
    font-weight: bold;
    color: #007bff;
}
