.site-footer {
    background-color: #000000;
    padding: 1px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.footer-subtitle {
    color: #ffffff;
    font-size: 1.2em;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
}

.sns-footer-logos {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 15%;
    padding-top: 20px;

    @media (max-width: 768px) {
        width: 50%;
    }
}

.footer-message {
    color: #ffffff;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;

    @media (max-width: 768px) {
        padding-left: 5px;
        padding-right: 5px;
    }
}