.social-media {
    padding: 1px;
    background-color: #000000;
    width: 100%;
}

.sns-title {
    font-size: 2em;
    margin-bottom: 0;
    margin-top: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    color: #ffffff;

    @media (max-width: 768px) { /* Adjust max-width to your tablet or smartphone breakpoint */
        font-size: 1.5em;
    }
}

.sns-container {
    display: flex; /* Ensure image and text (later) are flex items */
    align-items: flex-start; /* Align items to the start (left) */
    margin-top: 20px; /* Adjust as needed */

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.social-media-image {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;
    max-width: 15%; /* Ensure image doesn't exceed its container width */
    display: block; /* Prevent inline-block whitespace issues */
}

@media (max-width: 768px) {
    .social-media-image {
        display: none; /* Hide image on smaller screens */
    }
}

.current-container {
    padding-left: 5px;
    padding-right: 100px;

    @media (max-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.accounts-text {
    color: #ffffff;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    display: flex; /* Ensure image and text (later) are flex items */
    align-items: flex-start; /* Align items to the start (left) */

    @media (max-width: 768px) { /* Adjust max-width to your tablet or smartphone breakpoint */
        padding-left: 10px;
    }

    @media (max-width: 600px) { /* Adjust max-width to your tablet or smartphone breakpoint */
        font-size: 0.95em;
        padding-left: 10px;
    }
}

.sns-items-row{
    display: flex; /* Ensure image and text (later) are flex items */
    align-items: flex-start; /* Align items to the start (left) */
    flex-direction: row;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.sns-item-parent{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;

    @media (max-width: 768px) {
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.sns-item {
    max-width: 200px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    display: inline-block; /* Ensures anchor tag only takes up the necessary width */
    text-align: center; /* Aligns contents to the left */
    display: flex; /* Ensure image and text (later) are flex items */
    align-items: flex-start; /* Align items to the start (left) */
    flex-direction: column;
    
    @media (max-width: 768px) { /* Adjust max-width to your tablet or smartphone breakpoint */
        max-width: 60%;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 10px;
    }
}

.sns-item a {
    display: inline-block; /* Ensures anchor tag only takes up the necessary width */
    text-align: left; /* Aligns contents to the left */
    
    @media (max-width: 768px) {
        max-width: 100%;
    }
}

.sns-image {
    max-width: 100%; /* Ensure image doesn't exceed its container width */
    display: block; /* Prevent inline-block whitespace issues */
}

.sns-logos {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    

    @media (max-width: 768px) {
        padding-bottom: 20px;
    }
}